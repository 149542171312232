import React from 'react';
import { graphql, Link as GatsbyLink } from 'gatsby';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import translateContent from '@core/helpers/translation';
import ParagraphComponent from '@core/helpers/paragraph-helpers';
import { isContentTypeHero } from '@core/helpers/hero';

import Breadcrumb from '@shared/breadcrumbs/breadcrumbs';
import EventCard from '@shared/card/event';

import { Graphql } from '@models/graphql';

import allNodePartnershipQuery from './default-query';

const { partnership: crumbs } = require('@config/breadcrumbs');

type PartnershipsProps = {
  language: string,
  data: {
    partnership: Graphql
  };
};

export default function PartnershipsPage(
  { data, language }: PartnershipsProps
): React.ReactElement {
  const { t } = useTranslation();
  const { allEvents } = allNodePartnershipQuery();
  const { partnership } = data;

  const paragraphs = partnership?.relationships?.paragraphs;
  const events = translateContent(allEvents?.edges ?? [], language);

  const partnerEvents = events.filter((event) => {
    const { relationships } = event?.node || {};

    if (!relationships) {
      return false;
    }

    if (relationships.partnership && relationships.partnership.length > 0) {
      if (relationships.partnership.some((partner: Graphql) => partner.id === partnership.id)) {
        return true;
      }
    }

    return false;
  });

  const partnerUpcomingEvents: Graphql[] = [];
  const partnerPastEvents: Graphql[] = [];

  partnerEvents.forEach((event: Graphql) => {
    if (event?.node?.dateFormatted) {
      const date = moment(`${event.node.dateFormatted}`);
      const now = moment(new Date());

      if (date.isValid()) {
        if (!date.isSameOrAfter(now, 'day')) {
          partnerPastEvents.push(event);
        } else {
          partnerUpcomingEvents.push(event);
        }
      }
    }
  });

  if (!paragraphs || (paragraphs && paragraphs?.length <= 0)) {
    return <></>;
  }

  const content = paragraphs?.map(
    (node) => ParagraphComponent({ ...node, path: partnership.path }, language)
  );

  const hero = isContentTypeHero(paragraphs[0]) ? content.shift() : <></>;

  return (
    <div className="page-components-container partnerships-layout-page-container">
      {hero}
      <Breadcrumb crumbs={crumbs.concat([{ label: partnership.title, link: '#' }])} />
      {content}
      {(partnerUpcomingEvents.length > 0 || partnerPastEvents.length > 0) && (
        <div className="container related-events mt-4">
          <div className="row">
            <div className="col">
              <h4 className="mt-4 mb-4">{ t('Our events together') }</h4>
            </div>
          </div>
          <div className="row">
            {
              partnerUpcomingEvents.slice(0, 1).map((event) => (
                <div key={event.id} className="mb-5">
                  <EventCard key={`event-list-${event?.id}`} node={event?.node as Graphql} />
                </div>
              ))
            }
            {
              partnerPastEvents.slice(0, 1).map((event) => (
                <div key={event.id} className="mb-5">
                  <EventCard key={`event-list-${event?.id}`} node={event?.node as Graphql} />
                </div>
              ))
            }
            <div className="d-flex align-items-end align-bottom py-4">
              <GatsbyLink
                className="small font-weight-bold text-cool-blue text-sm-left pl-4"
                to={t('Path event page')}
              >
                {t('See more link')}
                {' >>'}
              </GatsbyLink>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export const query = graphql`
query($id: String!) {
  partnership: nodePartnership(id: { eq: $id }) {
    id
    title
    body {
      processed
      summary
    }
    path {
      alias
      langcode
    }
    relationships {
      paragraphs: field_content_main {
        type: __typename
        ...CtaParagraph
        ...HeroParagraph
        ...HeroSliderParagraph
        ...BannerParagraph
        ...BannerTalentParagraph
        ...BannerAdvParagraph
        ...QuoteSliderParagraph
        ...QuoteParagraph
        ...IntroTextParagraph
        ...IntroBlockParagraph
        ...TimelineParagraph
        ...CardsParagraph
        ...BoxesParagraph
        ...GridParagraph
        ...ClientsParagraph
        ...TechnologiesParagraph
        ...TabsParagraph
        ...TableParagraph
        ...WysiwygParagraph
        ...ViewParagraph
        ...FeaturedContentParagraph
        ...BannerFormParagraph
        ...BannerTalentApplyParagraph
        ...EmergencyModalParagraph
        ...BannerVideoParagraph
        ...WebFormParagraph
        ...CollageParagraph
        ...FabParagraph
        ...ImageSliderParagraph
        ...OfficesParagraph
      }
    }
  }
}`;
